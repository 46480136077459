import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Творчі рішення Qovilex
			</title>
			<meta name={"description"} content={"Відкрийте для себе відмінність Qovilex - інновації та творчість"} />
			<meta property={"og:title"} content={"Творчі рішення Qovilex"} />
			<meta property={"og:description"} content={"Відкрийте для себе відмінність Qovilex - інновації та творчість"} />
			<meta property={"og:image"} content={"https://up.qovilex.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://up.qovilex.com/img/social-media.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://up.qovilex.com/img/social-media.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://up.qovilex.com/img/social-media.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://up.qovilex.com/img/social-media.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://up.qovilex.com/img/social-media.png"} />
			<meta name={"msapplication-TileImage"} content={"https://up.qovilex.com/img/social-media.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Про нас
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Відкрийте для себе Qovilex
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Qovilex народилася з простої ідеї: зробити ефективний маркетинг доступним для всіх компаній, як великих, так і малих. Наш шлях почався з поєднання традиційних методів маркетингу з цифровими інноваціями для створення унікальних, ефективних кампаній, які виділяються на переповненому ринку. Сьогодні ми пишаємося тим, що є першопрохідцями у сфері креативних маркетингових рішень, постійно розширюючи межі можливого.
					</Text>
				</Box>
				<Image src="https://up.qovilex.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://up.qovilex.com/img/4.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Наш творчий підхід
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					У Qovilex креативність лежить в основі всього, що ми робимо. Ми використовуємо поєднання художньої виразності та стратегічного мислення для створення маркетингових повідомлень, які є не лише візуально привабливими, але й переконливими за своїм змістом. Наша команда складається з експертів у різних галузях маркетингу, кожен з яких привносить свій унікальний погляд на проблему, забезпечуючи цілісність і комплексність наших рішень.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Чому обирають нас? Виділяйтеся з Qovilex
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Інноваційні кампанії
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Ми не просто слідуємо за трендами - ми їх створюємо. Наші кампанії розроблені так, щоб випереджати криву, щоб вигідно вирізнятися з-поміж інших.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Індивідуальні рішення
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Кожен бізнес унікальний, як і наші стратегії. Ми адаптуємо кожну кампанію до ваших конкретних потреб і цілей.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Сталі практики
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					У всіх наших стратегіях ми прагнемо до сталого розвитку, допомагаючи вам побудувати довговічний бренд без шкоди для майбутнього нашої планети.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Доведений успіх
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Маючи за плечима багаторічний досвід, наші проекти постійно демонструють високі показники залученості та конверсії.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});